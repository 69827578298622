import APIService from '@/services/APIService.js'

export default {
    authentication (email, password) {
        return APIService.apiCallUnauthourized()
        .post('/users/login.json', {
            email: email,
            password: password
        })
    },
    logout (email, password) {
        return APIService.apiCall().get('/users/logout.json')
    },
    sendRequestEmail(nome, email) {
        const postData = {
            nome: nome,
            email: email
        }
        return APIService.apiCall().post(
            '/users/send_request_email.json',
            JSON.stringify(postData),
        )
    },
    postUser (dataUser,idUser=null) {
        const postData = { ...dataUser }
        if(idUser) {
          return APIService.apiCall().post('/users/edit/'+idUser+'.json',JSON.stringify(postData))
        }
        return APIService.apiCall().post('/users/add.json',JSON.stringify(postData))
    },
    getUsers () {
        return APIService.apiCall().get('/users.json')
    },
    getUser (id) {
        return APIService.apiCall().get('/users/view/'+id+'.json')
    },
    getLoggedUser () {
        return APIService.apiCall().get('/users/logged.json')
    },
    toggleActive (id){
        return APIService.apiCall().get('/users/toggleActive/'+id+'.json')
    }
}
