<template>
  <v-container
    id="regular-forms"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="success"
          inline
          title="Usuário"
          class="px-5 py-3"
        >

          <v-form class="pt-1" @submit.prevent="postUser">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Nome"
            >
              <v-text-field
                label="Nome *"
                outlined
                class="ml-4"
                :error-messages="errors"
                v-model="name"
              />
            </validation-provider>

            <validation-provider
              v-if="!this.$route.params.id"
              v-slot="{ errors }"
              rules="required"
              name="Email"
            >

              <v-text-field
                
                label="Email *"
                outlined
                class="ml-4"
                :error-messages="errors"
                v-model="email"
              />

            </validation-provider>


            <validation-provider
              v-if="true/*!this.$route.params.id*/"
              v-slot="{ errors }"
              rules="required"
              name="Senha"
            >

              <v-text-field
                type="password"
                label="Senha *"
                outlined
                v-model="password"
                class="ml-4"
                :error-messages="errors"
              />

            </validation-provider>

            <v-checkbox
              prepend-icon="mdi-account-cog"
              label="Administrador"
              v-model="admin"
            />

            <v-card-actions class="pl-0">
              <v-btn
                color="success"
                min-width="100"
                type="submit"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import UsersService from '@/services/UsersService'

  export default {
    name: 'EditUser',

    components: { 

    },

    data: () => ({
      name: "",
      email: "",
      password: "",
      admin: ""
    }),

    mounted () {
      if(this.$route.params.id){
        this.getUser(this.$route.params.id)
      }
    },

    computed: {
      
    },

    methods: {

      postUser() {
        let data = []

        data['name']      =  this.name
        data['email']     =  this.email
        data['password']  =  this.password
        data['admin']     =  this.admin

        UsersService.postUser(data,this.$route.params.id)
        .then((response) => {
          console.log(response)
          if(response.data.message == "Erro"){
            throw 500
          }
          this.$toast.success('Usuário Salvo Sucesso.', '',{position:'topRight'})
          this.$router.push({ name: 'Usuários' })
        })
        .catch(error => {
          console.log(error)
          this.$toast.error('Erro ao salvar.', '',{position:'topRight'})
        })
      },

      getUser() {
        UsersService.getUser(this.$route.params.id)
        .then(response => {
          this.name = response.data.user.name,
          this.email = response.data.user.email,
          this.password = response.data.user.password,
          this.admin = response.data.user.admin
        })
        .catch(error => {
          console.log(error)
          this.$toast.error('Erro ao recuperar Usuário.', '',{position:'topRight'})
        })
      },
    },
  }
</script>